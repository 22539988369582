function checkNip(el) {
    var nip_bez_kresek = el.value.replace(/-/g,"");
    var reg = /^[0-9]{10}$/;
    if(reg.test(nip_bez_kresek) == false) {
        el.errors.push("Podany numer NIP nie jest prawidłowy");
        return false;
    }
    else
    {
        var dig = (""+nip_bez_kresek).split("");
        var kontrola = (6*parseInt(dig[0]) + 5*parseInt(dig[1]) + 7*parseInt(dig[2]) + 2*parseInt(dig[3]) + 3*parseInt(dig[4]) + 4*parseInt(dig[5]) + 5*parseInt(dig[6]) + 6*parseInt(dig[7]) + 7*parseInt(dig[8]))%11;
        if(parseInt(dig[9])==kontrola)
            return true;
        else{
            el.errors.push("Podany numer NIP nie jest prawidłowy");
            return false;
        }
    }
 }


 function checkPesel(el) {
     var pesel = el.value;
    var reg = /^[0-9]{11}$/;

    if(reg.test(pesel) == false) {
        el.errors.push("Podany numer PESEL nie jest prawidłowy");
        return false;
    }
    else
    {
        var dig = (""+pesel).split("");
        var kontrola = (1*parseInt(dig[0]) + 3*parseInt(dig[1]) + 7*parseInt(dig[2]) + 9*parseInt(dig[3]) + 1*parseInt(dig[4]) + 3*parseInt(dig[5]) + 7*parseInt(dig[6]) + 9*parseInt(dig[7]) + 1*parseInt(dig[8]) + 3*parseInt(dig[9]))%10;
        if(kontrola==0) kontrola = 10;
        kontrola = 10 - kontrola;
        if(parseInt(dig[10])==kontrola)
            return true;
        else{
            el.errors.push("Podany numer PESEL nie jest prawidłowy");
            return false;
        }
    }

}

 function checkPostcode(el) {
    var postcode = el.value;
    var reg = /^\d{2}-\d{3}$/;

    if (reg.test(postcode) == false) {
        el.errors.push("Podany kod pocztowy nie jest prawidłowy");
           return false;
    }

    return true;
}

function checkDate(el) {
    var birthDate = el.value;
    var reg = /^\d{2}.\d{2}.\d{4}$/;
    if (reg.test(birthDate) == false) {
        el.errors.push("Podany format daty nie jest prawidłowy");
            return false;
    }

    return true;
}
